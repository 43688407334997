<template>
  <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">
    <div class="space-y-1 pb-8">
      <router-link
        to="/reports/sales-by-day"
        class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
        aria-current="false"
      >
        <CashIcon
          class="-ml-1 mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        />
        <span class="truncate text-lg"> Sales</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import { useStore } from "vuex";

import { CashIcon } from "@heroicons/vue/outline";

const navigation = [];

export default {
  components: {
    CashIcon,
  },
  setup() {
    const store = useStore();
    return {
      store,
      navigation,
    };
  },
};
</script>

<style scoped>
.text-lg {
  font-size: 0.95rem;
}
</style>
